<template>
  <div v-if="isOpen">
    <div v-if="processing">{{message}}</div>
    <div v-if="processing == false" class="grid-row">
      <div class="form-group">
        <input class="person-vorname form-control lth-wk-input" :class="{'input--error': formErrors.name2}" placeholder="Vorname *" type="text" v-model="formValues.name2" @input="handleInputChange">
      </div>
      <div class="form-group">
        <input class="person-nachname form-control lth-wk-input" :class="{'input--error': formErrors.name1}" placeholder="Nachname *" type="text" value="" v-model="formValues.name1" @input="handleInputChange">
      </div>
      <!-- <div class="form-group">
        <input class="person-email form-control lth-wk-input" :class="{'input--error': formErrors.emailorphone}" placeholder="Email" type="text" value="" v-model="formValues.email" @input="handleInputChange">
      </div> -->

      <!-- <div class="form-group">
        <input class="person-telefon form-control lth-wk-input" :class="{'input--error': formErrors.emailorphone}" placeholder="Telefon" type="text" value="" v-model="formValues.phone" @input="handleInputChange">
      </div> -->
    </div>
    <div v-if="processing == false" style="display:grid;grid-template-columns:1fr 1fr;">
      <div class="form-check lth-wk-input" style="margin-bottom:0;">
        <input type="checkbox" class="form-check-input" :id="`datenverarbeitung_${eventId}_${seatId}`" v-model="formValues.datenverarbeitung" @change="handleInputChange">
        <label class="form-check-label" :for="`datenverarbeitung_${eventId}_${seatId}`" >Ich bin berechtigt, diese Personendaten zu übermitteln.</label>
        <div v-if="formErrors.datenverarbeitung">
          <span class="error">Bitte bestätigen Sie die Datenverarbeitung</span>
        </div>
      </div>
      <!-- <div v-if="formErrors.emailorphone">
        <span class="error">Bitte geben Sie entweder Telefonnummer oder Email-Adresse an.</span>
      </div> -->
    </div>
    <div v-if="processing == false" class="grid-row">
      <button type="button" class="btn btn-danger pull-right btn-lg" :class="{'btn--disabled': !canSave}"  :disabled="!canSave" @click="savePersonalization">speichern</button>
      <button class="form-control btn lth-wk-input" type="button" @click="openModal">Informationen zur Personalisierung</button>
      <button class="form-control btn lth-wk-input" type="button" @click="fillCustomerData">Käuferdaten Einfügen</button>
      <button class="form-control btn lth-wk-input" type="button" @click="deleteData">Formular leeren</button>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import eventbus from '../../eventbus.js';
import axios from 'axios';

export default {
  props: {
    eventId: {
      type: Number,
      required: true,
    },
    seatId: {
      type: Number,
      required: true,
    }
  },
  computed: {
    ...mapState(['API']),
    canSave() {
      return this.formErrors.datenverarbeitung === false &&
        this.formErrors.name1 == false &&
        this.formErrors.name2 == false // && this.formErrors.emailorphone == false;
    },
    hasFormErrors() {
      return Object.keys(this.formErrors).filter((errorname) => { return this.formErrors[errorname] == true}).length > 0;
    }
  },
  data() {
    return {
      isOpen: false,
      message: 'Daten werden geladen... bitte warten',
      processing: true,
      buyeremail: '',
      formValues: {
        name2:'',
        name1:'',
        email:'',
        phone:'',
        datenverarbeitung: false,
        ticketemail: false,
      },
      formErrors: {
        name1: false,
        name2: false,
        // emailorphone: false,
        datenverarbeitung: false,
      },
      apiData: {},
    }
  },
  methods: {
    closeModal() {
      eventbus.$emit('PERSONALIZATION_REFRESH');
      this.isOpen = false;
    },
    async fillCustomerData() {
      const {data} = await axios.get(this.API, {
        params: {
          action: 'getPersonalizationBuyerData'
        }
      });
      this.buyeremail = data.email;
      this.setValuesFromAPI(data);
      this.formValues.datenverarbeitung = false;
      this.checkFormErrors();
    },
    setValuesFromAPI(apiData) {
      this.formValues.name2 = apiData.name2;
      this.formValues.name1 = apiData.name1;
      // this.formValues.email = apiData.email;
      // this.formValues.phone = apiData.phone;
      this.formValues.datenverarbeitung = false;
    },
    async getPersonalizationData() {
      this.processing = true;
      this.message = 'Daten werden geladen... bitte warten';
      const {data} = await axios.get(this.API, {
        params: {
          action: 'getPersonalizationData',
          seatId: this.seatId,
          eventId: this.eventId
        }
      });
      this.setValuesFromAPI(data);
      this.apiData = data;
      this.processing = false;
    },
    async deleteData() {
      this.processing = true;
      this.message = 'Daten werden gelöscht... bitte warten';
      this.setValuesFromAPI({});
      await this.savePersonalization(false);
      this.processing = false;
    },
    isFilled(value) {
      return value !== '' && value != undefined && value != null;
    },
    checkFormErrors() {
      this.formErrors.name1 = this.isFilled(this.formValues.name1) == false;
      this.formErrors.name2 = this.isFilled(this.formValues.name2) == false;
      // this.formErrors.emailorphone = this.isFilled(this.formValues.email) == false && this.isFilled(this.formValues.phone) == false;
      this.formErrors.datenverarbeitung = this.formValues.datenverarbeitung !== true;
    },
    handleInputChange() {
      this.checkFormErrors();
      if (this.hasFormErrors) {
        return;
      }
      this.savePersonalization(false);
    },
    async savePersonalization(checkForm = true) {
      // formcheck
      if (checkForm === true) {
        this.checkFormErrors();
        if (this.hasFormErrors) {
          return;
        }
      }
      this.processing = true;
      this.message = 'Daten werden gespeichert... bitte warten';
      const {data} = await axios.post(this.API, {
        action: 'personalisierung',
        seatId: this.seatId,
        eventId: this.eventId,
        buyeremail: this.buyeremail,
        name2: this.formValues.name2,
        name1: this.formValues.name1,
        // email: this.formValues.email,
        // phone: this.formValues.phone,
      });
      this.processing = false;
      if (data.error == 0) {
        eventbus.$emit('PERSONALIZATION_REFRESH');
        this.closeModal();
      }
    },
    open(eventId, seatId) {
      if (eventId == this.eventId && seatId == this.seatId) {
        this.getPersonalizationData();
        this.isOpen = !this.isOpen;
      }
    },
    openModal() {
      eventbus.$emit('PERSONALIZATION_MODAL_OPEN');
    }
  },
  created() {
    eventbus.$on('PERSONALIZATION_INLINE_OPEN', ({eventId, seatId}) => {
      if (eventId == this.eventId && seatId == this.seatId) {
        this.open(eventId, seatId)
      } else {
        this.isOpen = false;
      }
    });
  },
  beforeDestroy() {
    eventbus.$off('PERSONALIZATION_INLINE_OPEN');
  }
}
</script>
<style scoped>
  .grid-row {
    display: grid;
    grid-template-columns:1fr 1fr 1fr 1fr;
    grid-gap: 12px;
  }
  .btn-container {
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 16px;
  }

  .form-group {
    margin-top: 8px;
    margin-bottom: 8px;
  }

  .btn {
    font-size: 18px;
    font-family: "HeroicCondensed-Heavy";
    text-transform: uppercase;
    line-height: 27px;
    background: white;
    border: 2px solid black;
    display: inline-block;
    color: black;
    text-align: center;
    padding: 6px 11px;
  }
  input[type="text"] {
    border: 1px solid #CCC;
    padding: 9px;
    width: 100%;
    max-width: 350px;
  }
  input[type="text"].input--error {
    border-color: #CC0000;
    border-style: solid;
    border-width: 1px;
  }
  input[type="text"].input--error:focus {
    border-color: #CC0000;
  }
  .error {
    color: #CC0000;
    font: 20px/20px 'HeroicCondensed-Book';
  }
  .btn--disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }

  input[type="checkbox"] + label {
    margin-left: 8px;
  }
</style>
