<template>
  <input
    type="button"
    name="lth-order-submit"
    class="lth-wk-btn"
    :value="caption"
    @click="continueOrder()"
    style="float:right;" />
</template>
<script>
import { mapState } from 'vuex';
import eventbus from '../../eventbus.js';
import axios from 'axios';
export default {
  props: {
    continue: {
      type: Boolean,
      default: false,
      required: false,
    },
    caption: {
      type: String,
      default: 'Bestätigen',
    }
  },
  computed: {
    ...mapState(['API']),
  },
  methods: {
    async continueOrder() {
      const {data} = await axios.get(this.API, {
        params: {
          action: 'allSeatsPersonalized',
        }
      });
      console.log('allSeatsPersonalized', data);
      if (data.error === false || this.continue === true) {
        document.forms[0].submit();
        return
      }
      eventbus.$emit('PERSONALIZATION_MODAL_OPEN');
      // eventbus.$emit('PERSONALIZATION_INLINE_OPEN', {seatId: data.seatId, eventId: data.eventId});
      return
    }
  }
}
</script>
